import {graphql, PageProps} from 'gatsby';
import React from 'react';
import {PageQuery} from '../../types/graphql-types';
import {FeaturesComponent} from 'components/features/FeaturesComponent';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {LanguageEnum} from '../../locales/types';

const Features: React.FC<PageProps<PageQuery>> = ({data}) => {
  const {language} = useI18next();
  switch (language) {
    case LanguageEnum.ca:
      return <FeaturesComponent page={data.pageEs.page} />;
    case LanguageEnum.es:
      return <FeaturesComponent page={data.pageEs.page} />;
    case LanguageEnum.fr:
      return <FeaturesComponent page={data.pageFr.page} />;
    default:
      return <FeaturesComponent page={data.pageEn.page} />;
  }
};

export default Features;

export const query = graphql`
  query Features {
    locales: allLocale(filter: {ns: {in: ["common", "features"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pageEn: prismic {
      page(uid: "features", lang: "en-gb") {
        title
        body
        _meta {
          uid
          lang
        }
      }
    }
    pageEs: prismic {
      page(uid: "features", lang: "es-es") {
        title
        body
        _meta {
          uid
          lang
        }
      }
    }
    pageFr: prismic {
      page(uid: "features", lang: "fr-fr") {
        title
        body
        _meta {
          uid
          lang
        }
      }
    }
  }
`;
