// i18next-extract-mark-ns-start features
import { ContactSalesButton } from "components/ContactSalesButton";
import { Content } from "components/Content";
import { Section, SectionActions, SectionHeader } from "components/Section";
import { SEO } from "components/SEO";
import { SignUpButton } from "components/SignUpButton";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

//Images
import omnichannel from "images/omnichannel.svg";

//Testimonial logos
//Partners
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { htmlSerializer } from "utils/htmlSerializer";
import { RichText } from "prismic-reactjs";
import { ScreenSizes } from "../../../types/responsive";
import { List } from "../List";
import Link from "gatsby-link";
import { GradientBox, GradientCtaImage, GradientSectionDescription } from "../landings/GradientBox";
import monei_pay_form_en from "images/monei_pay_form_en.png";
import monei_pay_form_es from "images/monei_pay_form_es.png";
import { LanguageEnum } from "../../../locales/types";
import { IosDownloadLink } from "../DownloadOnAppleStore";
import { AndroidDownloadLink } from "../DownloadOnGooglePlay";
import { normalizeString } from "utils/helpers";

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -8.9%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1335, -10) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -11.5%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, -7.4deg);
    }
    @media (max-width: ${ScreenSizes.md}) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(38deg) matrix(1, 0, 0, -1, 378, -318);
    left: 50%;
    top: -3%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -13%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: ${ScreenSizes.md}) {
      content: none;
    }
  }
`;

const IndexImage = styled.img`
  position: absolute;
  left: 52%;
  top: 30px;
  width: 500px;
  z-index: 9;

  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 0;
  max-width: 550px;
  min-height: 600px;
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 100%;
  }
`;

const IndexColumn = styled.div`
  flex: 1;

  #features-list {
    margin: 0;
    &.sticky {
      position: fixed;
      top: 80px;
      max-width: 28vw;
      white-space: normal;
    }
    &.sticky-bottom {
      position: absolute;
      bottom: 0;
    }
  }

  @media (max-width: ${ScreenSizes.md}) {
    #features-list {
      &.sticky {
        position: inherit;
        top: inherit;
        max-width: inherit;
        white-space: normal;
      }
      &.sticky-bottom {
        position: inherit;
        bottom: inherit;
      }
    }
  }
`;
const ContentColumn = styled.div`
  flex: 3;
`;

const ListItem = styled.li`
  list-style: none;
  position: relative;
  padding-left: 12px;
  margin-bottom: 0;
  padding-bottom: calc(1.64rem / 2);

  &:before {
    content: '';
    position: absolute;
    width: 2.5px;
    height: 100%;
    background-color: #d7d7d7;
    left: 0;
    top: 0;
    transition: background-color 0.3s ease;
  }

  &.active::before {
    background-color: #646464;
  }
`;

const getAnchorLink = (text: string) => {
  const replaced =
    '#' +
    text
      .toLowerCase()
      .replace(/\s/g, '-')
      .replace(/[\(\)]/g, '');
  // many titles come with an ending space so we need to strip it
  return replaced[replaced.length - 1] === '-' ? replaced.slice(0, replaced.length - 1) : replaced;
};

const getListItemId = (el: HTMLElement) => {
  return normalizeString(el.getAttribute('href')?.split('#')[1].replace('#', ''));
};
const goTo = (ev: React.MouseEvent<HTMLAnchorElement>) => {
  ev.preventDefault();

  const href = ev.target.getAttribute('href');
  const id = normalizeString(href.split('#')[1].replace('#', ''));
  const element = document.getElementById(id) as HTMLElement;
  if (element) {
    const rects = element.getBoundingClientRect();
    const stickyBannerHeight = 56;
    const elementOffsetY = rects.top - rects.height;
    const top = elementOffsetY - stickyBannerHeight;
    window.scrollBy({top, behavior: 'smooth'});
  }
};

const getSiblings = (element: HTMLElement): HTMLElement[] => {
  const siblings: HTMLElement[] = [];
  if (!element.nextSibling || element.nextSibling.nodeName === 'H2') {
    return siblings;
  }
  siblings.push(element.nextSibling as HTMLElement);
  return [...siblings, ...getSiblings(element.nextSibling as HTMLElement)];
};
const getSiblingsHeights = (element: HTMLElement) => {
  const siblings = getSiblings(element);
  return siblings.reduce((acc, b) => {
    return acc + b.scrollHeight;
  }, 0);
};

export const FeaturesComponent: React.FC<{page: any}> = ({page}) => {
  const {t, language} = useI18next();
  const isSpanish = [LanguageEnum.es, LanguageEnum.ca].includes(language as LanguageEnum);
  const monei_pay_form = isSpanish ? monei_pay_form_es : monei_pay_form_en;
  const [scrollTop, setScrollTop] = useState(0);

  const setupScroll = () => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    window.requestAnimationFrame(() => null);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }

  const removeUnwantedLanguages = () => {
    console.log('removeUnwantedLanguages')
    const footer = document.getElementById('footer') as HTMLElement;
    const flags = footer.querySelectorAll('ul li');
    flags.forEach((flag) => {
      if (['it','ca','de','pt'].includes(flag.querySelector('a')?.getAttribute('hreflang') as string)) {
        flag.setAttribute('style', 'display: none');
      }
    })
  }

  useEffect(() => {
    setupScroll();
    setTimeout(removeUnwantedLanguages, 200);
  }, []);

  useEffect(() => {
    const featuresList = document.getElementById('features-list') as HTMLElement;
    const featuresContent = document.getElementById('features-content') as HTMLElement;
    const featuresContentRects = featuresContent.getBoundingClientRect();
    const listItems = Array.from(featuresList?.querySelectorAll('li'));
    listItems.forEach((item) => {
      const itemId = getListItemId(item.querySelector('a') as HTMLElement);
      if (!itemId) return;
      const targetFeature = document.getElementById(itemId) as HTMLElement;
      const targetFeatureRects = targetFeature.getBoundingClientRect();
      const featureHeight = targetFeatureRects.height + getSiblingsHeights(targetFeature);
      if (
        targetFeatureRects.top < window.innerHeight / 3 &&
        targetFeatureRects.bottom + featureHeight > 60
      ) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });

    if (featuresContentRects.top < 100 && featuresContentRects.bottom > window.innerHeight / 1.3) {
      featuresList.classList.add('sticky');
    } else {
      featuresList.classList.remove('sticky');
    }
    if (featuresContentRects.bottom < window.innerHeight / 1.3 && featuresContentRects.bottom > 0) {
      featuresList.classList.add('sticky-bottom');
    } else {
      featuresList.classList.remove('sticky-bottom');
    }
  }, [scrollTop]);

  if (!page) return null;
  const headingSections = page.body.filter((item) => item.type === 'heading2');

  const pageTitle = page.title[0].text;
  const pageTitleDescription = page.body[0].text;

  const body: any[] = Array.from(page.body);

  if (body[0].type === 'paragraph') {
    body.shift();
  }

  return (
    <>
      <SEO
        title={t('Omnichannel Payment Features')}
        description={t(
          'Learn about MONEI’s omnichannel payment features and how they can help you grow your business.'
        )}
      />
      <IndexBackground>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              {pageTitle}
            </SectionHeader>
            <p>{pageTitleDescription}</p>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
          </IndexContent>
        </Content>
        <IndexImage
          src={omnichannel}
          alt="MONEI payment gateway"
          title="MONEI payment gateway"
          className="hide-on-mobile"
        />
      </IndexBackground>

      <Content>
        <Section sx={{paddingBottom: '0', paddingTop: {md: '0'}}}>
          <SectionHeader underline>
            <Trans>Features overview</Trans>
          </SectionHeader>
        </Section>
        <Section
          sx={{
            paddingTop: '0',
            paddingBottom: '0',
            marginBottom: '150px',
            display: {md: 'block'},
            gap: '24px'
          }}>
          <IndexColumn>
            <List sx={{marginLeft: '0'}} id="features-list">
              {headingSections.map((h2) => {
                const lastChar = h2.text[h2.text.length - 1];
                const text = /\s/.test(lastChar) ? h2.text.slice(0, -1) : h2.text;
                return (
                  <ListItem>
                    <Link to={getAnchorLink(h2.text)} onClick={goTo} activeClassName={'active'}>
                      {text}
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </IndexColumn>
          <ContentColumn id="features-content">
            <RichText render={body} htmlSerializer={htmlSerializer} />
          </ContentColumn>
        </Section>
      </Content>
      <Content>
        <GradientBox>
          <SectionHeader tagName="h3" underline light>
            <Trans>Power your omnichannel payments with MONEI</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Get started today (without obligation) to test integrations and payment methods, and
              download your mobile payment app on one or many <IosDownloadLink>iOS</IosDownloadLink>{' '}
              or <AndroidDownloadLink>Android</AndroidDownloadLink> mobile devices.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              {' '}
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <GradientCtaImage src={monei_pay_form} width={300} />
        </GradientBox>
      </Content>
    </>
  );
};
